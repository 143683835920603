import React, { Component } from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom';
// import Login from '../pages/Login';
import Blog from '../pages/Blog';
import Error404 from '../pages/Error404';
import CursosNacionales from '../pages/CursosNacionales';
import CursosInternacionales from '../pages/CursosInternacionales';
import App from '../App';
import CursosDiplomados from '../pages/CursosDiplomados';
function Routes() {
   return (
      <BrowserRouter>
         <Switch>
            <Route exact path='/' component={Blog} />
            <Route exact path='/CursosNacionales' component={CursosNacionales} />
            <Route exact path='/CursosInternacionales' component={CursosInternacionales} />
            <Route exact path='/CursosDiplomados' component={CursosDiplomados} />
            <Route exact path='/app' component={App} />
            <Route component={Error404}/>
         </Switch>
      </BrowserRouter>
   );
}
export default Routes;