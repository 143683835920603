import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SendTelegram from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Carrusel from '../componentes/Carrusel';
import Spinner from '../componentes/Spinner';


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
}));

const sections = [
    { title: 'Technology', url: '#' },
    { title: 'Design', url: '#', active: true },
    { title: 'Culture', url: '#' },
    { title: 'Business', url: '#' },
    { title: 'Politics', url: '#' },
    { title: 'Opinion', url: '#' },
    { title: 'Science', url: '#' },
    { title: 'Health', url: '#' },
    { title: 'Style', url: '#' },
    { title: 'Travel', url: '#' },
];

const mainFeaturedPost = {
    title: 'BOLVIRTUAL',
    description: "Realizamos cursos, talleres, capacitaciones y webinar's en línea para diferentes áreas y especialidades.",
    image: '/img/banner/b3.jpg',
    imgText: 'www.cybermaq.com',
    linkText: 'BolVirtual',
};

const featuredPosts = [
    {
        title: 'Cursos, talleres NACIONALES',
        date: 'Virtuales',
        description: 'Entra aquí y encontraras los cursos y talleres virtuales que realizamos.',
        image: '/img/1.jpg',
        imageText: 'www.cybermaq.com',
        url: '/CursosNacionales'
    },
    {
        title: 'Cursos, talleres INTERNACIONALES',
        date: 'Virtuales',
        description: 'Entrar aqui para ver los cursos y talleres virtuales INTERNACIONALES desarrollados.',
        image: '/img/2.jpg',
        imageText: 'www.cybermaq.com',
        url: '/CursosInternacionales'
    },
    // {
    //     title: 'DIPLOMADOS',
    //     date: '2021',
    //     description: 'Entrar aqui para ver los diplomados desarrollados en ésta gestión 2021.',
    //     image: '/img/3.jpg',
    //     imageText: 'www.cybermaq.com',
    //     url: '/CursosDiplomados'
    // },
];

const posts = [
    
    // {   
    //     titulo: 'Curso de capacitación: Manejo Práctico de la Plataforma Educativa Nacional Moodle.',
    //     img: '/img/cursosytalleres/c5.jpg',
    //     descripcion: 'Curso de Capacitación Nacional: Manejo Práctico de la Plataforma Educativa Nacional Moodle, donde aprenderas desde cero hasta el nivel intermedio el uso de la plataforma educativa.',
    //     fechapubli: '10 de julio 2021',
    //     grupo1: 'https://chat.whatsapp.com/IQPzi90Sq0I7CSB6sqnUek',
    //     // grupo2: '',
    //     inscribirse: 'CursosNacionales' //CursosInternacionales   CursosNacionales
    // },
    // {   
    //     titulo: 'Curso de capacitación Nacional: Gestión de la Plataforma Educativa Moodle - MinEdu y Perfil del Docente en Línea.',
    //     img: '/img/cursosytalleres/c3.jpg',
    //     descripcion: 'Curso de capacitación Nacional: Gestión de la Plataforma Educativa Moodle - MinEdu y Perfil del Docente en Línea, con triple certificación.',
    //     fechapubli: '24 de mayo 2021',
    //     grupo1: 'https://chat.whatsapp.com/Jq98rffqj9N0wq9zpY90Fu',
    //     grupo2: 'https://chat.whatsapp.com/INqWtLDODHYFM6axjabzJX',
    //     inscribirse: 'CursosNacionales' //CursosInternacionales   CursosNacionales
    // },
];

const sidebar = {
    title: 'Transmisiones',
    description: 'Nuestros cursos y talleres virtuales son gratuitos y esta disponibles en nuestra pagina de facebook o pagina de youtube, estan disponibles al horario de su comodidad. Si quiere capacitación personalizada de algún tema en particular comunicarse con 79136233 - 67779628.',
    archives: [
        { title: 'Exámenes onLine', url: 'https://examenesonline.cybermaq.com', descripcion: 'Elaborar en menos de 5 minutos tus exámenes en línea.' },
        { title: 'TrabajoPROFES', url: 'https://trabajoprofes.cybermaq.com', descripcion: 'Publica y revisa tus permutas, suplencias, ofertas laborales y publicitar tu especialidad.' }
    ],
    social: [
        { name: 'Página Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/BolVirtual-102117735335933' },
        { name: 'Grupo Telegram', icon: SendTelegram, url: 'https://t.me/joinchat/aaThcJnNdZNjMzc5'},
        { name: 'Canal Youtube', icon: YoutubeIcon, url: 'https://www.youtube.com/channel/UC4MWVL52Z5KBFXOP-K0Bq0A' },
        // { name: 'Grupo Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/groups/353619069077252' },
        // { name: 'Grupo Signal', icon: MessageRoundedIcon, url: 'https://signal.group/#CjQKIBPiMO2nwcTgNXzhabU7S6zepWPulOef-EqGckPUSwfLEhA3Wwy_ofbQpnGztV32QvkT' },
        // { name: 'Grupo 1 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/FKA6qa1g8PgFYeINf9s3DY' },
        // { name: 'Grupo 2 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/J8ej4ipnFi87gHyUu5BapX' },
        // { name: 'Grupo 3 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CrW90fRcBCr0xfLCPOwrYL' },
        // { name: 'Grupo 4 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/FOdDZXOGDmn1FXqGDH1ZvC' },
        // { name: 'Grupo 5 de whatsapp', icon: WhatsAppIcon, url: 'https://chat.whatsapp.com/CA1jhw411I3KV1giJzcesR' },
        // { name: 'Twitter', icon: TwitterIcon, url: 'https://www.twitter.com/' },
        // { name: 'Grupo Maestr@s de nivel INICIAL', icon: FacebookIcon, url: 'https://www.facebook.com/groups/2051894518285257' },
        // { name: 'Grupo Maestr@s de nivel PRIMARIA', icon: FacebookIcon, url: 'https://www.facebook.com/groups/438690387347347' },
        // { name: 'Grupo Maestr@s de nivel SECUNDARIA', icon: FacebookIcon, url: 'https://www.facebook.com/groups/481813622825881' },
    ],
};

export default function Blog() {
    const classes = useStyles();

    const [loading, setLoading] = useState(false);
    const cambiarEstado=()=>{
        setLoading(true);
        setTimeout(()=>{
            setLoading(false);
        },5000)
    }
    if(loading){
        return(
            <Spinner/>
        )
    } else {
        return ( <React.Fragment>
            <CssBaseline/>
            <Container maxWidth = "lg" >
                <Header title="BolVirtual" sections={sections}/> 
                <main>
                    <MainFeaturedPost post = { mainFeaturedPost }/>
                    {/* <Carrusel/>  */}
                    <Grid container spacing = { 4 } > {
                        featuredPosts.map((post) => ( <FeaturedPost
                            FeaturedPost key = { post.title }
                            post = { post }
                            />
                        ))
                    } 
                    </Grid> 
                    <Grid container alignItems="flex-start" spacing = { 2 } className = { classes.mainGrid } >
                        <Main title = "Novedades y actualidades de cursos o talleres que se realizan." posts = { posts }/> 
                        <Sidebar title = { sidebar.title }
                        description = { sidebar.description }
                        archives = { sidebar.archives }
                        social = { sidebar.social }/> 
                    </Grid> 
                </main> 
            </Container>
            <Footer title = "BolVirtual" description = "Más información puede comunicarse con 79136233 - 67779628" / >
            </React.Fragment>
        );
    }
}