import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SendTelegram from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import BlockIcon from '@material-ui/icons/Block';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import Header2 from './Header2';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Carrusel from '../componentes/Carrusel';
import MainCursosytalleres from './MainCursosytalleres';


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(1),
    }
}));

const posts = [
    { 
        id: 3,
        titulo: 'Curso Internacional: Microsoft Office 2021',
        img: '/img/cursosytalleres/c4.jpg', 
        descripcion: 'Curso Internacional: Microsoft Office Manejo, Administración e instalación de los paquetes Word, Excel, Power Point, Publisher.',
        fechapublicacion: '21 de junio de 2021',
        activo: BlockIcon, // CheckOutlinedIcon  BlockIcon
        registrarse: true,
        certificado: true,
        sesiones: false,
        cdescarga: 'f_ioffice',
        grupolink: 'https://chat.whatsapp.com/Bn7EiePTyJBI61Slr0ZIMV',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Diseño y desarrollo de página web educativa.',
                sesionlink: 'https://fb.watch/5nJ63rArvS/',
            },
            // {
            //     sesiondesc: 'SESIÓN 2: ClassRoom y sus herramientas exclusivas para la educación.',
            //     sesionlink: 'https://fb.watch/5nJ9oJJp1H/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 3: Problemas, causas y soluciones al aprendizaje de los estudiantes en la pandemia.',
            //     sesionlink: 'https://fb.watch/5nJaGpsulO/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 4: Evaluaciones virtuales autocalificables 2021.',
            //     sesionlink: 'https://fb.watch/5nJd8FUtLM/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 5: Proyecto descartes 2021.',
            //     sesionlink: 'https://fb.watch/5nJd8FUtLM/',
            // },
        ],

        habilitardescarga: 'N',
        imgcertificado: '/img/certificados/c4/office.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 178,
        qrleft: 670,
        citop: 163,
        cileft: 670,
    },
    { 
        id: 2,
        titulo: 'Taller Internacional: Manejo Mútiple de Herramientas y Plataformas Educativas 2021 - Nivel Avanzado',
        img: '/img/cursosytalleres/c2a.jpg', 
        descripcion: 'Taller Internacional: Manejo Mútiple de Herramientas y Plataformas Educativas, donde se expondran diferentes temáticas a nivel internacional.',
        fechapublicacion: '25 de abril de 2021',
        activo: BlockIcon, // CheckOutlinedIcon  BlockIcon
        registrarse: true,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_in1a',
        grupolink: 'https://chat.whatsapp.com/I7HxPXYPCoFFOWxRLlReyT',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Diseño y desarrollo de página web educativa.',
                sesionlink: 'https://fb.watch/5nJ63rArvS/',
            },
            {
                sesiondesc: 'SESIÓN 2: ClassRoom y sus herramientas exclusivas para la educación.',
                sesionlink: 'https://fb.watch/5nJ9oJJp1H/',
            },
            {
                sesiondesc: 'SESIÓN 3: Problemas, causas y soluciones al aprendizaje de los estudiantes en la pandemia.',
                sesionlink: 'https://fb.watch/5nJaGpsulO/',
            },
            {
                sesiondesc: 'SESIÓN 4: Evaluaciones virtuales autocalificables 2021.',
                sesionlink: 'https://fb.watch/5nJd8FUtLM/',
            },
            {
                sesiondesc: 'SESIÓN 5: Proyecto descartes 2021.',
                sesionlink: 'https://fb.watch/5nJd8FUtLM/',
            },
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/c2/c2_a.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 178,
        qrleft: 690,
        citop: 163,
        cileft: 690,
    },
    { 
        id: 1,
        titulo: 'Taller Internacional: Manejo Mútiple de Herramientas y Plataformas Educativas 2021 - Nivel Básico',
        img: '/img/cursosytalleres/c2b.jpg', 
        descripcion: 'Taller Internacional: Manejo Mútiple de Herramientas y Plataformas Educativas, donde se expondran diferentes temáticas a nivel internacional.',
        fechapublicacion: '25 de abril de 2021',
        activo: BlockIcon, // CheckOutlinedIcon  BlockIcon
        registrarse: true,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_in1b',
        grupolink: 'https://chat.whatsapp.com/GGH9R6yBLSe4bD1YYusZnh',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Diseño y desarrollo de página web educativa.',
                sesionlink: 'https://fb.watch/5nJ63rArvS/',
            },
            {
                sesiondesc: 'SESIÓN 2: ClassRoom y sus herramientas exclusivas para la educación.',
                sesionlink: 'https://fb.watch/5nJ9oJJp1H/',
            },
            {
                sesiondesc: 'SESIÓN 3: Problemas, causas y soluciones al aprendizaje de los estudiantes en la pandemia.',
                sesionlink: 'https://fb.watch/5nJaGpsulO/',
            },
            {
                sesiondesc: 'SESIÓN 4: Evaluaciones virtuales autocalificables 2021.',
                sesionlink: 'https://fb.watch/5nJd8FUtLM/',
            },
            {
                sesiondesc: 'SESIÓN 5: Proyecto descartes 2021.',
                sesionlink: 'https://fb.watch/5nJd8FUtLM/',
            },
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/c2/c2_b.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 178,
        qrleft: 690,
        citop: 163,
        cileft: 690,
    },
];

const sidebar = {
    title: 'Aviso',
    description: 'Nuestros cursos y talleres virtuales son gratuitos y esta disponibles en nuestra pagina de facebook o pagina de youtube, estan disponibles al horario de su comodidad. Si quiere capacitación personalizada de algún tema en particular comunicarse con 79136233 - 67779628.',
    archives: [
        { title: 'Exámenes onLine - EXONLI', url: 'https://examenesonline.cybermaq.com', descripcion: 'Elaborar en menos de 5 minutos tus exámenes en línea.' },
        { title: 'TrabajoPROFES', url: 'https://trabajoprofes.cybermaq.com', descripcion: 'Publica y revisa tus permutas, suplencias, ofertas laborales y publicitar tu especialidad.' }
    ],
    social: [
        { name: 'Página Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/BolVirtual-102117735335933' },
        { name: 'Grupo Telegram', icon: SendTelegram, url: 'https://t.me/joinchat/aaThcJnNdZNjMzc5'},
        { name: 'Canal Youtube', icon: YoutubeIcon, url: 'https://www.youtube.com/channel/UC4MWVL52Z5KBFXOP-K0Bq0A' },
    ],
};

export default function CursosInternacionales() {
    const classes = useStyles();
    return ( <React.Fragment>
        <CssBaseline/>
        <Container maxWidth = "lg" >
            <Header2 title="Cursos, Talleres Internacionales virtuales 2021" /> 
            <main>
                <Carrusel/> 
                
                <Grid container alignItems="flex-start" spacing = { 2 } className = { classes.mainGrid } >
                    <MainCursosytalleres
                        title = "Cursos y talleres virtuales Internacionales, que se realizan se publican aquí." 
                        posts = { posts }
                        sesionesgrabadas = { posts.sesionesgrabadas }
                        /> 
                    <Sidebar title = { sidebar.title }
                        description = { sidebar.description }
                        archives = { sidebar.archives }
                        social = { sidebar.social }/> 
                </Grid> 
            </main> 
        </Container>
        <Footer title = "BolVirtual" description = "Más información puede comunicarse con 79136233 - 67779628" / >
        </React.Fragment>
    );
}