import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SendTelegram from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import BlockIcon from '@material-ui/icons/Block';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import Header2 from './Header2';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Carrusel from '../componentes/Carrusel';
import MainCursosytalleres from './MainCursosytalleres';


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    }
}));

const posts = [
    { 
        id: 5,
        titulo: 'Curso de capacitación: Manejo Práctico de la Plataforma Educativa Nacional Moodle',
        img: '/img/cursosytalleres/c5.jpg', 
        descripcion: 'Curso de Capacitación Nacional: Manejo Práctico de la Plataforma Educativa Nacional Moodle, donde aprenderas desde cero hasta el nivel intermedio el uso de la plataforma educativa.',
        fechapublicacion: '23 de junio de 2021',
        activo: BlockIcon, // CheckOutlinedIcon  BlockIcon
        registrarse: true,
        certificado: true,
        sesiones: false,
        cdescarga: 'f_mp',
        grupolink: 'https://chat.whatsapp.com/IQPzi90Sq0I7CSB6sqnUek',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
                sesionlink: 'https://fb.watch/5nIBvKLqZH/',
            },
            // {
            //     sesiondesc: 'SESIÓN 1 - 2da parte: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
            //     sesionlink: 'https://fb.watch/5nIF5GE-UN/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 2: Administración de la plataforma educativa Moodle ROL docentes.',
            //     sesionlink: 'https://fb.watch/5nIOMTL_g7/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 3: Administración de la plataforma educativa Moodle ROL docentes.',
            //     sesionlink: 'https://fb.watch/5nITs1MFaj/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 4: Administración de la plataforma educativa Moodle ROL docentes.',
            //     sesionlink: 'https://fb.watch/5nIVjTD4fz/',
            // },
        ],

        habilitardescarga: 'N',
        imgcertificado: '/img/certificados/c5/c5.jpg',
        orientacion: 'landscape',
        nombrestop: 190,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 178,
        qrleft: 690,
        citop: 163,
        cileft: 690,
    },
    { 
        id: 4,
        titulo: 'Curso de capacitación Nacional: Gestión de la Plataforma Educativa Moodle MinEdu y Perfil del Docente en Línea - nivel INTERMEDIO',
        img: '/img/cursosytalleres/c3.jpg', 
        descripcion: 'Curso de capacitación Nacional: Gestión de la Plataforma Educativa Moodle - MinEdu y Perfil del Docente en Línea. Para maestros de Ed. Regular, Ed. Alternativa y Ed. Especial, con triple certificación.',
        fechapublicacion: '24 de mayo de 2021',
        activo: BlockIcon, // CheckOutlinedIcon  BlockIcon
        registrarse: true,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c3_2',
        grupolink: 'https://chat.whatsapp.com/INqWtLDODHYFM6axjabzJX',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
                sesionlink: 'https://fb.watch/5nIBvKLqZH/',
            },
            // {
            //     sesiondesc: 'SESIÓN 1 - 2da parte: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
            //     sesionlink: 'https://fb.watch/5nIF5GE-UN/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 2: Administración de la plataforma educativa Moodle ROL docentes.',
            //     sesionlink: 'https://fb.watch/5nIOMTL_g7/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 3: Administración de la plataforma educativa Moodle ROL docentes.',
            //     sesionlink: 'https://fb.watch/5nITs1MFaj/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 4: Administración de la plataforma educativa Moodle ROL docentes.',
            //     sesionlink: 'https://fb.watch/5nIVjTD4fz/',
            // },
        ],

        habilitardescarga: 'N',
        imgcertificado: '/img/certificados/c3/intermedio.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 178,
        qrleft: 690,
        citop: 163,
        cileft: 690,
    },
    { 
        id: 3,
        titulo: 'Curso de capacitación Nacional: Gestión de la Plataforma Educativa Moodle MinEdu y Perfil del Docente en Línea - nivel BASICO',
        img: '/img/cursosytalleres/c3.jpg', 
        descripcion: 'Curso de capacitación Nacional: Gestión de la Plataforma Educativa Moodle - MinEdu y Perfil del Docente en Línea. Para maestros de Ed. Regular, Ed. Alternativa y Ed. Especial, con triple certificación.',
        fechapublicacion: '24 de mayo de 2021',
        activo: BlockIcon, // CheckOutlinedIcon  BlockIcon
        registrarse: true,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c3_1',
        grupolink: 'https://chat.whatsapp.com/INqWtLDODHYFM6axjabzJX',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
                sesionlink: 'https://fb.watch/5nIBvKLqZH/',
            },
            // {
            //     sesiondesc: 'SESIÓN 1 - 2da parte: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
            //     sesionlink: 'https://fb.watch/5nIF5GE-UN/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 2: Administración de la plataforma educativa Moodle ROL docentes.',
            //     sesionlink: 'https://fb.watch/5nIOMTL_g7/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 3: Administración de la plataforma educativa Moodle ROL docentes.',
            //     sesionlink: 'https://fb.watch/5nITs1MFaj/',
            // },
            // {
            //     sesiondesc: 'SESIÓN 4: Administración de la plataforma educativa Moodle ROL docentes.',
            //     sesionlink: 'https://fb.watch/5nIVjTD4fz/',
            // },
        ],

        habilitardescarga: 'N',
        imgcertificado: '/img/certificados/c3/basico.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 178,
        qrleft: 690,
        citop: 163,
        cileft: 690,
    },
    { 
        id: 2,
        titulo: 'Curso Nacional: Administración de la Plataforma Educativa Moodle del MinEdu Bolivia, Sub. Ed. ALTERNATIVA Y ESPECIAL',
        img: '/img/cursosytalleres/c1a.jpg', 
        descripcion: 'Curso Nacional: Administración de la Plataforma Educativa Moodle del MinEdu Bolivia, para Docentes, Estudiantes o padres de familia de Educación ALTERNATIVA Y ESPECIAL.',
        fechapublicacion: '12 de abril de 2021',
        activo: BlockIcon, // CheckOutlinedIcon  BlockIcon
        registrarse: true,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c1a',
        grupolink: 'https://chat.whatsapp.com/COKiRpcHMPRDYq5kEz2EKT',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
                sesionlink: 'https://fb.watch/5nIBvKLqZH/',
            },
            {
                sesiondesc: 'SESIÓN 1 - 2da parte: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
                sesionlink: 'https://fb.watch/5nIF5GE-UN/',
            },
            {
                sesiondesc: 'SESIÓN 2: Administración de la plataforma educativa Moodle ROL docentes.',
                sesionlink: 'https://fb.watch/5nIOMTL_g7/',
            },
            {
                sesiondesc: 'SESIÓN 3: Administración de la plataforma educativa Moodle ROL docentes.',
                sesionlink: 'https://fb.watch/5nITs1MFaj/',
            },
            {
                sesiondesc: 'SESIÓN 4: Administración de la plataforma educativa Moodle ROL docentes.',
                sesionlink: 'https://fb.watch/5nIVjTD4fz/',
            },
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/c1/c1_a.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 178,
        qrleft: 690,
        citop: 163,
        cileft: 690,
    },
    {
        id: 1,
        titulo: 'Curso Nacional: Administración de la Plataforma Educativa Moodle del MinEdu Bolivia, Sub. Ed. REGULAR',
        img: '/img/cursosytalleres/c1.jpg', 
        descripcion: 'Curso Nacional: Administración de la Plataforma Educativa Moodle del MinEdu Bolivia, para Docentes, Estudiantes o padres de familia de EDUCACIÓN REGULAR.',
        fechapublicacion: '12 de abril de 2021',
        activo: BlockIcon, // CheckOutlinedIcon  BlockIcon
        registrarse: true,
        certificado: true,
        sesiones: true,
        cdescarga: 'f_c1',
        grupolink: 'https://chat.whatsapp.com/FZpNRUHcQcL1ht3cYanOMi',
        sesionesgrabadas:[
            {
                sesiondesc: 'SESIÓN 1: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
                sesionlink: 'https://fb.watch/5nIBvKLqZH/',
            },
            {
                sesiondesc: 'SESIÓN 1 - 2da parte: Administración de la plataforma educativa Moodle ROL estudiantes y padres de familia.',
                sesionlink: 'https://fb.watch/5nIF5GE-UN/',
            },
            {
                sesiondesc: 'SESIÓN 2: Administración de la plataforma educativa Moodle ROL docentes.',
                sesionlink: 'https://fb.watch/5nIOMTL_g7/',
            },
            {
                sesiondesc: 'SESIÓN 3: Administración de la plataforma educativa Moodle ROL docentes.',
                sesionlink: 'https://fb.watch/5nITs1MFaj/',
            },
            {
                sesiondesc: 'SESIÓN 4: Administración de la plataforma educativa Moodle ROL docentes.',
                sesionlink: 'https://fb.watch/5nIVjTD4fz/',
            },
        ],

        habilitardescarga: 'S',
        imgcertificado: '/img/certificados/c1/c1_r.jpg',
        orientacion: 'landscape',
        nombrestop: 200,
        nombresleft: 90,
        nombresright: 100,
        qrtop: 178,
        qrleft: 690,
        citop: 163,
        cileft: 690,
    },
];

const sidebar = {
    title: 'Aviso',
    description: 'Nuestros cursos y talleres virtuales son gratuitos y esta disponibles en nuestra pagina de facebook o pagina de youtube, estan disponibles al horario de su comodidad. Si quiere capacitación personalizada de algún tema en particular comunicarse con 79136233 - 67779628.',
    archives: [
        { title: 'Exámenes onLine', url: 'https://examenesonline.cybermaq.com', descripcion: 'Elaborar en menos de 5 minutos tus exámenes en línea.' },
        { title: 'TrabajoPROFES', url: 'https://trabajoprofes.cybermaq.com', descripcion: 'Publica y revisa tus permutas, suplencias, ofertas laborales y publicitar tu especialidad.' }
    ],
    social: [
        { name: 'Página Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/BolVirtual-102117735335933' },
        { name: 'Grupo Telegram', icon: SendTelegram, url: '#'},
        { name: 'Canal Youtube', icon: YoutubeIcon, url: 'https://www.youtube.com/channel/UC4MWVL52Z5KBFXOP-K0Bq0A' },
    ],
};

export default function CursosNacionales() {
    const classes = useStyles();
    return ( <React.Fragment>
        <CssBaseline/>
        <Container maxWidth = "lg" >
            <Header2 title="Cursos, Talleres Nacionales virtuales" /> 
            <main>
                <Carrusel/> 
                
                <Grid container alignItems="flex-start" spacing = { 2 } className = { classes.mainGrid } >
                    <MainCursosytalleres
                        title = "Cursos y talleres virtuales Nacionales, que se realizan se publican en esta área." 
                        posts = { posts }
                        sesionesgrabadas = { posts.sesionesgrabadas }
                        /> 
                    <Sidebar title = { sidebar.title }
                        description = { sidebar.description }
                        archives = { sidebar.archives }
                        social = { sidebar.social }/> 
                </Grid> 
            </main> 
        </Container>
        <Footer title = "BolVirtual" description = "Más información puede comunicarse con 79136233 - 67779628" / >
        </React.Fragment>
    );
}