import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SendTelegram from '@material-ui/icons/Telegram';
import FacebookIcon from '@material-ui/icons/Facebook';
import BlockIcon from '@material-ui/icons/Block';
import TwitterIcon from '@material-ui/icons/Twitter';
import YoutubeIcon from '@material-ui/icons/YouTube';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import Header2 from './Header2';
import MainDiplomados from './MainDiplomados';
import Sidebar from './Sidebar';
import Footer from './Footer';
import Carrusel from '../componentes/Carrusel';


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(1),
    }
}));

const posts = [
    // { 
    //     id: 1,
    //     titulo: "DIPLOMADO: Gestión y Administración de Centros Educativos.",
    //     img: '/img/cursosytalleres/d1_1.jpg', 
    //     descripcion: "DIPLOMADO: Gestión y Administración de Centros Educativos, primer diplomado de la Mastría en Educación Superior.",
    //     fechapublicacion: '10 de abril de 2021',
    //     activo: CheckOutlinedIcon, // CheckOutlinedIcon  - BlockIcon
    //     registrarse: true,
    //     certificado: false,
    //     sesiones: true,
    //     cdescarga: 'f_m1d1',
    //     sesionesgrabadas:[
    //         {
    //             sesiondesc: 'SESIÓN 1: Elabora tu blog informativo educativo.',
    //             sesionlink: '',
    //         },
    //         {
    //             sesiondesc: 'SESIÓN 2: Aplicaciones y programas informáticos para tus clases.',
    //             sesionlink: '',
    //         },
    //         {
    //             sesiondesc: 'SESIÓN 3: TIC, TAC, TEP conceptos y herramientas.',
    //             sesionlink: '',
    //         },
    //         {
    //             sesiondesc: 'SESIÓN 4: Aplicaciones virtuales para la educacion a distancia y presencial.',
    //             sesionlink: '',
    //         },
    //         {
    //             sesiondesc: 'SESIÓN 5: MOODLE Plataforma virtual del MINEDU - Bolivia.',
    //             sesionlink: '',
    //         },
    //     ],

    //     habilitardescarga: 'N',
    //     imgcertificado: '/img/certificados/c9_tac.jpg',
    //     orientacion: 'portrait',
    //     nombrestop: 300,
    //     nombresleft: 100,
    //     qrtop: 310,
    //     qrleft: 500,
    //     citop: 297,
    //     cileft: 500,
    // },
];

const sidebar = {
    title: 'Aviso',
    description: 'Los Diplomados que realizamos se transmiten mediante la plataforma ZOOM. En nuestras redes sociales nos puede encontrar con los diversos talleres que ya se realizaron.',
    archives: [
        { title: 'Exámenes onLine - EXONLI', url: 'https://examenesonline.cybermaq.com', descripcion: 'Elaborar en menos de 5 minutos tus exámenes en línea.' },
        { title: 'TrabajoPROFES', url: 'https://trabajoprofes.cybermaq.com', descripcion: 'Publica y revisa tus permutas, suplencias, ofertas laborales y publicitar tu especialidad.' }
    ],
    social: [
        { name: 'Página Facebook', icon: FacebookIcon, url: 'https://www.facebook.com/ClassOnline-110882097751924' },
        { name: 'Grupo Telegram', icon: SendTelegram, url: 'https://t.me/classonlinecybermaq'},
        { name: 'Canal Youtube', icon: YoutubeIcon, url: 'https://www.youtube.com/channel/UC4MWVL52Z5KBFXOP-K0Bq0A' },
    ],
};

export default function CursosDiplomados() {
    const classes = useStyles();
    return ( <React.Fragment>
        <CssBaseline/>
        <Container maxWidth = "lg" >
            <Header2 title="Diplomados 2021" /> 
            <main>
                <Carrusel/> 
                
                <Grid container alignItems="flex-start" spacing = { 2 } className = { classes.mainGrid } >
                    <MainDiplomados
                        title = "Diplomados, que se realizan se publican en ésta sección, si quiere mas información al 62536641 o 70117490." 
                        posts = { posts }
                        sesionesgrabadas = { posts.sesionesgrabadas }
                        /> 
                    <Sidebar title = { sidebar.title }
                        description = { sidebar.description }
                        archives = { sidebar.archives }
                        social = { sidebar.social }/> 
                </Grid> 
            </main> 
        </Container>
        <Footer title = "ClassOnline CyberMAQ" description = "Más información puede comunicarse con 62536641 - 68023629 - 70117490" / >
        </React.Fragment>
    );
}