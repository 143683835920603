import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ReactPDF, {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  Font,
  PDFDownloadLink,
} from '@react-pdf/renderer';
import { Button } from '@material-ui/core/';
import {
  CloudDownload
} from '@material-ui/icons';
import QRCode from 'qrcode';

Font.register({
  family: 'Oswald',
  src: '/font/Oswald.ttf'
});

export default function Certificados(props) {
  
  const { 
    ctitulocurso, 
    cimgcertificado, 
    cnombresapellidos, 
    cci, 
    cnombrestop,
    cnombresleft,
    cnombresright,
    cqrtop,
    cqrleft,
    ccitop,
    ccileft,
    corientacion } = props;
  var opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    quality: 0.3,
    margin: 1,
    color: {
      dark:"#fff",
      light:"#00031b"
    }
  }
  // Create styles
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
    },
    section: {
      position: 'absolute',
      top: cnombrestop,
      left: cnombresleft,
      right: cnombresright,
      textAlign: 'center',
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    pageBackground: {
      position: 'absolute',
      minWidth: '100%',
      minHeight: '100%',
      display: 'block',
      height: '100%',
      width: '100%',
    },
  })
  const [codqr, setCodqr] = useState("");
  QRCode.toDataURL(ctitulocurso + ' Participante: ' +cnombresapellidos+ ' CI: ' +cci, opts)
    .then(img => {
      setCodqr(img);
      //  console.log(img);
  })
  

  const MyDoc = () => (
    <Document>
      <Page size="Letter" orientation={corientacion} style={styles.page}>
        <Image src={cimgcertificado || '/img/certificados/blanco.jpg'} style={styles.pageBackground} />
        <View style={styles.section}>
            <Text style={{
                fontSize: '26',
                textTransform: 'uppercase',
                fontFamily: 'Oswald'}}> {cnombresapellidos} </Text>
        </View>
        <View style= {{
              top: cqrtop, 
              left: cqrleft,
              position: 'absolute',}} >
            <Image cache={false} style={{width:81, height:81}} src={codqr} crossorigin="anonymous"/>
        </View>
        <View style={{
              top: ccitop, 
              left: ccileft, 
              position: 'absolute',}}>
            <Text style={{
                fontSize: '14',
                textTransform: 'uppercase',
                textAlign: 'center'}}> {cci} </Text>
        </View>
      </Page>
    </Document>
  )
  
  const ImgQr = () => (
    <div>
      <PDFDownloadLink document={<MyDoc />} fileName= {cnombresapellidos + " - CyberMAQ.pdf"}>
        {({ blob, url, loading, error }) => (
          loading ? 
          'Creando tu certificado...' : 
          <Button
              variant="contained"
              color="secondary"
              size="large"
              style= {{width: 300}}
              startIcon={<CloudDownload />}> Descargar ahora </Button> )}
      </PDFDownloadLink>
    </div>
  )

  return (
    <div>
      <img width="120px" src={codqr}></img>
      <ImgQr />
      {/* {ctitulocurso}<br />
      {cimgcertificado}<br />
      {cnombresapellidos}<br />
      {cci}<br /> */}
      {/* {cnombrestop}<br />
    {cnombresleft}<br />
    {cqrtop}<br />
    {cqrleft}<br />
    {ccitop}<br />
    {ccileft}<br /> */}
     </div>
  );
}
Certificados.propTypes = {
    // archives: PropTypes.array,
    ctitulocurso: PropTypes.string,
    cimgcertificado: PropTypes.string,
    corientacion: PropTypes.string,
    cnombresapellidos: PropTypes.string,
    cci: PropTypes.string,
    chabilitado: PropTypes.string,

    cnombrestop: PropTypes.string,
    cnombresleft: PropTypes.string,
    cnombresright: PropTypes.string,
    cqrtop: PropTypes.string,
    cqrleft: PropTypes.string,
    ccitop: PropTypes.string,
    ccileft: PropTypes.string,
  };
