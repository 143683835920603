import React, {useEffect, useState} from 'react';
import {
  Container, 
  Typography, 
  Box,
  Paper,
  Link,
  Modal,
  Checkbox,
  FormControlLabel,
  TextField,
  CssBaseline,
  Button,
  InputLabel,
  NativeSelect, 
  CircularProgress,
  FormHelperText,
  TableCell,
  TableRow,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar} from '@material-ui/core';
  import Alert from '@material-ui/lab/Alert';
  import PropTypes from 'prop-types';
  import { makeStyles } from '@material-ui/core/styles';
  import Grid from '@material-ui/core/Grid';
  import Divider from '@material-ui/core/Divider';
  import clsx from 'clsx';
  import Card from '@material-ui/core/Card';
  import CardHeader from '@material-ui/core/CardHeader';
  import CardMedia from '@material-ui/core/CardMedia';
  import CardContent from '@material-ui/core/CardContent';
  import CardActions from '@material-ui/core/CardActions';
  import Collapse from '@material-ui/core/Collapse';
  import IconButton from '@material-ui/core/IconButton';
  import { orange } from '@material-ui/core/colors';
  import FavoriteIcon from '@material-ui/icons/Favorite';
  import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
  import MoreVertIcon from '@material-ui/icons/MoreVert';
  import PersonAddIcon from '@material-ui/icons/PersonAdd';
  import ShareIcon from '@material-ui/icons/Share';
  import VideocamIcon from '@material-ui/icons/Videocam';
  import {
    GetApp, 
    Search, 
    Edit, 
    Delete, 
    Add, 
    Save, 
    AttachFile,
    Cancel, 
    Check, 
    Visibility,
    Send,
    LiveTv,
  } from '@material-ui/icons';

  import BottomNavigation from '@material-ui/core/BottomNavigation';
  import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';

  import Notificaciones from "../componentes/Notificaciones";
  import APIRest from '../services/Conexion';

  import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
  import Certificados from '../componentes/Certificados';
  import Certificadosqr from '../componentes/Certificadosqr';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  media: {
    height: 0,
    paddingTop: '80%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  activo: {
    backgroundColor: orange[500],
  },
  
  modal: {
    position: 'fixed',
    width: '350px',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #ccc',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(1,2,2),
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconos:{
      cursor: 'pointer'
  },
  inputMaterial:{
      width: '100%'
  }
}));

export default function MainDiplomados(props) {
  const classes = useStyles();
  const { posts, sesionesgrabadas, title } = props;

  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

    const [value, setValue] = React.useState(4);

    const [nombresapellidos, setNombresapellidos] = useState("");
    const [ci, setCi] = useState("");
    const [celular, setCelular] = useState("");
    const [departamento, setDepartamento] = useState("");
    const [cicelular, setCicelular] = useState("");
    const [errors, setErrors] = useState("");
    const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' })


    const [mostrar, setMostrar] = useState([]);
    const [loscursos, setLoscursos] = useState([]);
    const [modalInsertar, setModalInsertar] = useState(false);
    const [modalBusca, setModalBusca] = useState(false);
    const [modalSesiones, setModalSesiones] = useState(false);
    const [consolaSeleccionado, setConsolaSeleccionado] = useState ({
        id: '',
        f_nombresapellidos: '',
        f_ci: '',
        f_celular: '',
        f_departamento: ''
    })
    const [consolaBusca, setConsolaBusca] = useState ({
      cicelular: '',
      columna: ''
    })

    const [expandedacord, setExpandedacord] = React.useState(false);
    const handleChangeacord = (panel) => (event, isExpanded) => {
      setExpandedacord(isExpanded ? panel : false);
    };


    const handleChange = e => {
        const {name, value} = e.target;
        if(name == 'cicelular'){
          setErrors({cicelular: ''})
          setCicelular(value);
          if(value.length < 5){
            setCicelular({cicelular: 'Debe tener al menos 5 caracteres'})
          } else if(value.length > 15){
            setCicelular({cicelular: 'Debe tener menor a 15 caracteres'})
          } else {
              setConsolaBusca(prevState => ({
                ...prevState,
                [name]: value
            }));
          }
        } else {
          setConsolaBusca(prevState => ({
            ...prevState,
            [name]: value
          }));
        }
    }
    const handleChange2 = e => {
        const {name, value} = e.target;
        if(name == 'f_nombresapellidos'){
          setErrors({nombresapellidos: ''})
          setNombresapellidos(value);
          let reg = new RegExp(/(\w+)\s(\w+)/).test(value)
          if(!reg){
            setNombresapellidos({nombresapellidos: 'Debe ingresar nombres y apellidos'})
          }
          if(value.length < 5){
            setNombresapellidos({nombresapellidos: 'Debe tener al menos 5 caracteres'})
          } else if (value.length > 90){
            setNombresapellidos({nombresapellidos: 'No puede ser mas de 90 caracteres'})
          } else {
              setConsolaSeleccionado(prevState => ({
                ...prevState,
                [name]: value
            }));
          }
        }
        if(name == 'f_ci'){
          setErrors({ci: ''})
          setCi(value);
          if(value.length < 5){
            setCi({ci: 'Debe tener al menos 5 caracteres'})
          } else if(value.length > 15){
            setCi({ci: 'Debe tener menor a 15 caracteres'})
          } else {
              setConsolaSeleccionado(prevState => ({
                ...prevState,
                [name]: value
            }));
          }
        }
        if(name == 'f_celular'){
          setErrors({celular: ''})
          setCelular(value);
          let reg2 = new RegExp(/^\d*$/).test(value)
          if(!reg2){
            setCelular({celular: 'Debe ingresar sólo números'})
          } else if(value.length == 0){
            setCelular({celular: 'No puede quedar vacio'})
          } else if(value.length < 8){
            setCelular({celular: 'Debe tener mas de 8 caracteres'})
          } else {
              setConsolaSeleccionado(prevState => ({
                ...prevState,
                [name]: value
            }));
          }
        }
        if(name == 'f_departamento'){
          setErrors({derpatamento: ''})
          setDepartamento(value);
          if(value.length == 0){
            setDepartamento({departamento: 'No debe querdar vacio'})
          } else {
              setConsolaSeleccionado(prevState => ({
                ...prevState,
                [name]: value
            }));
          }
        }

    }

    const [loading, setLoading] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const timer = React.useRef();
    const buttonClassname = clsx({
      [classes.buttonSuccess]: success,
    });
    const handleButtonClick = () => {
      if (!loading) {
        setSuccess(false);
        setLoading(true);
        timer.current = window.setTimeout(() => {
          setSuccess(true);
          setLoading(false);
        }, 2000);
      }
    };

    const fnombresapellidos = e => {
        const {name, value} = e.target;
        setErrors({nombresapellidos: ''})
        setNombresapellidos(value);
        let reg = new RegExp(/^\d*$/).test(value)
        if(!reg){
          setErrors({nombresapellidos: 'Only numbers are permited'})
        }
        // console.log(consolaSeleccionado);
    }
    const peticionGet = async() =>{
        await APIRest.get('/cursov1/listall')
        .then(response => {
           // console.log(response.data);
           setMostrar(response.data);
        }).catch(error => {
           console.log(error);
        })
    }
    const peticionPost = async() =>{
        setSuccess(false);
        setLoading(true);
        // timer.current = window.setTimeout(() => {
        //   setSuccess(true);
        //   setLoading(false);
        // }, 5000);
        await APIRest.post('/participantesDiplomados/registranuevo',consolaSeleccionado)
        .then(response => {
              /* setMostrar(data.concat(response.data)) */
            var respMsg=response.data['msg'];
            var success=response.data['success'];
              if(success === true) {
                    setSuccess(true);
                    setLoading(false);
                    setNotify({
                      isOpen: true,
                      message: 'Welcome: ' + respMsg,
                      type: 'success',
                      duration: '6000'
                    });
                } else {
                    setSuccess(true);
                    setLoading(false);
                    setNotify({
                      isOpen: true,
                      message: respMsg,
                      type: 'warning',
                      duration: '20000'
                    })
              }
            abrirCerrarModalInsertar();
          }).catch(error => {
            setSuccess(true);
            setLoading(false);
            console.log(error);
        });
          // await peticionGet();
    }

    const peticionBusca = async(e) =>{
      console.log(e);
      setConsolaBusca(prevState => ({
          ...prevState,
          ['columna']: e
      }));

      setSuccess(false);
      setLoading(true);
      // timer.current = window.setTimeout(() => {
      //     // setSuccess(true);
      //     // setLoading(false);
      // }, 2000);
      
      await APIRest.post('/participantesDiplomados/buscaParticipanteCol',consolaBusca)
      .then(response => {
            /* setMostrar(data.concat(response.data)) */
          // var respMsg=response.data['msg'];
          var success=response.data['success'];
            if(success === true) {
                setSuccess(true);
                setLoading(false);
                setMostrar(response.data['registros']);
                console.log(response.data['registros']);
            } else {
                setSuccess(true);
                setLoading(false);
                setMostrar(response.data['registros']);
            }
          // abrirCerrarModalInsertar();
        }).catch(error => {
          setSuccess(true);
                setLoading(false);
            console.log(error);
      });
        // await peticionGet();
    }

    const abrirCerrarModalInsertar = () =>{
        setModalInsertar(!modalInsertar);
        setConsolaSeleccionado([]);
    }
    const abrirCerrarModalBusca = (post) =>{
      if (post) { setLoscursos(post); }
        setModalBusca(!modalBusca);
        setMostrar([]);
        setConsolaBusca([]);
    }
    const abrirCerrarModalSesiones = (post) =>{
      if (post) { setLoscursos(post); }
        setModalSesiones(!modalSesiones);
        setMostrar([]);
        setConsolaBusca([]);
    }
    const seleccionarCosola = (consola, caso) => {
      setConsolaSeleccionado(consola);
        if (caso === 'Ver') { abrirCerrarModalBusca(); }
    }
    useEffect(async () => {
        // await peticionBusca();
    }, [])

    const bodyInsertar = (
        <div className={classes.modal}>
              <h3>Nuevo participante</h3>
              <FormHelperText>Los datos que ingrese aquí, serán utilizados al imprimir su certificado.</FormHelperText>
                <TextField
                  fullWidth
                  label="Nombres y apellidos"
                  name="f_nombresapellidos"
                  className={classes.inputMaterial}
                  // helperText="Datos personales"
                  placeholder="Datos personales"
                  inputProps = {{maxLength: 100}}
                  margin="dense"
                  variant="outlined"
                  required
                  onBlur = {handleChange2}
                  onChange = {handleChange2}
                  error = {Boolean(nombresapellidos?.nombresapellidos)}
                  helperText = {(nombresapellidos?.nombresapellidos)}
                />
              <TextField
                  fullWidth
                  label="C.I."
                  name="f_ci"
                  className={classes.inputMaterial}
                  placeholder="Documento de identidad"
                  margin="dense"
                  variant="outlined"
                  required
                  inputProps = {{maxLength: 17}}
                  onChange = {handleChange2}
                  onBlur = {handleChange2}
                  error = {Boolean(ci?.ci)}
                  helperText = {(ci?.ci)}
              />
              <TextField
                  fullWidth
                  label="Celular"
                  name="f_celular"
                  className={classes.inputMaterial}
                  placeholder="Número de celular"
                  margin="dense"
                  variant="outlined"
                  required
                  inputProps = {{maxLength: 15}}
                  onChange = {handleChange2}
                  onBlur = {handleChange2}
                  error = {Boolean(celular?.celular)}
                  helperText = {(celular?.celular)}
              />
              {/* <TextField
                  fullWidth
                  label="Departamento"
                  name="f_departamento"
                  className={classes.inputMaterial}
                  placeholder="Departamento"
                  margin="dense"
                  variant="outlined"
                  required
                  onBlur = {handleChange2}
                  onChange={handleChange2}
                  error = {Boolean(errors?.departamento)}
                  helperText = {(errors?.departamento)}
              /><br /> */}

                  <InputLabel htmlFor="name-native-disabled">Departamento</InputLabel>
                  <NativeSelect
                    // value={state.name}
                    variant="outlined"
                    onChange={handleChange2}
                    onBlur = {handleChange2}
                    inputProps={{
                      name: 'f_departamento',
                      id: 'name-native-disabled',
                    }}
                    className={classes.inputMaterial}
                    error = {Boolean(departamento?.departamento)}
                    >
                    <option value="">Elegir un Departamento</option>
                    <optgroup label="Bolivia">
                      <option value="Pando">Pando</option>
                      <option value="Beni">Beni</option>
                      <option value="La Paz">La Paz</option>
                      <option value="Cochabamba">Cochabamba</option>
                      <option value="Santa Cruz">Santa Cruz</option>
                      <option value="Oruro">Oruro</option>
                      <option value="Chuquisaca">Chuquisaca</option>
                      <option value="Tarija">Tarija</option>
                      <option value="Potosi">Potosi</option>
                    </optgroup>
                    <optgroup label="Internacional">
                      <option value="Internacional">Soy de otro país</option>
                    </optgroup>
                  </NativeSelect>

              <br /><br />
              <div align="right">

                  <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<Save />}
                      disableElevation
                      className={classes.button}
                      disabled={!nombresapellidos || nombresapellidos?.nombresapellidos || !ci || ci?.ci || !celular || celular?.celular || !departamento || departamento?.departamento || loading}
                      onClick={()=>peticionPost()}
                    >
                      Registrarme
                  </Button> {loading && <CircularProgress size={24} className={classes.buttonProgress} />}&nbsp;&nbsp;
                  <Button
                      variant="contained"
                      color="default"
                      size="small"
                      className={classes.button}
                      startIcon={<Cancel />}
                      onClick={()=>abrirCerrarModalInsertar()}
                      disableElevation>
                      Cancelar
                  </Button>
                 
              </div>
        </div>
    )
     
    const bodyBusca = (
        <div className={classes.modal}>
           <h3>{loscursos && loscursos.titulo}</h3>
           <FormHelperText>Ingrese celular o C.I. para buscar tu certificado.</FormHelperText>
                <TextField
                  fullWidth
                  label="Celular o C.I."
                  name="cicelular"
                  className={classes.inputMaterial}
                  // helperText="Datos personales"
                  placeholder="Ingrese Celular o CI"
                  inputProps = {{maxLength: 100}}
                  margin="dense"
                  variant="outlined"
                  required
                  onBlur = {handleChange}
                  onChange = {handleChange}
                  error = {Boolean(cicelular?.cicelular)}
                  helperText = {(cicelular?.cicelular)}
            />
            { mostrar.map((consola) => (
                <div align="left" key={consola.id}>
                    { consola.id &&  <TableRow>
                           <TableRow>Nombres: {consola.f_nombresapellidos.toUpperCase()} </TableRow>
                           <TableRow>C.I.: {consola.f_ci.toUpperCase()} </TableRow>
                           <TableRow>Celular: {consola.f_celular} </TableRow>
                           <TableRow>Departamento: {consola.f_departamento} </TableRow>
                      </TableRow>
                    }
                    {/* <Certificados 
                            ctitulocurso = { loscursos.titulo }
                            cimgcertificado = { loscursos.img }
                            cnombresapellidos = { consola.f_nombresapellidos}
                            cci = { consola.f_ci }
                            chabilitado = { consola.certif } /> */}
                    <Divider />
                    {consola.msg && <Alert severity="warning">{consola.msg}</Alert>}
                    {consola[loscursos.cdescarga] =='S' && loscursos.habilitardescarga==='N' &&
                        <Alert severity="success">Bien, tu certificado ya está en trámite, te escribiremos cuando ya esté disponible para coordinar la entrega o descargarlo de aqui mismo.</Alert>
                    }
                    {consola[loscursos.cdescarga] =='S'  && loscursos.habilitardescarga==='S' &&
                        <Certificadosqr
                            ctitulocurso = { loscursos.titulo }
                            corientacion = {loscursos.orientacion}
                            cnombresapellidos = { consola.f_nombresapellidos.toUpperCase()}
                            cci = { consola.f_ci.toUpperCase() } 
                            cnombrestop = {loscursos.nombrestop}
                            cnombresleft = {loscursos.nombresleft}
                            cqrtop = {loscursos.qrtop}
                            cqrleft = {loscursos.qrleft}
                            ccitop = {loscursos.citop}
                            ccileft = {loscursos.cileft} />
                    }
                    {consola[loscursos.cdescarga] =='S' && loscursos.habilitardescarga==='S' &&
                        // <Alert severity="success">Bien, tu certificado ya está en trámite, te escribiremos cuando ya esté disponible para coordinar la entrega o descargarlo de aqui mismo.</Alert>
                        <Certificados
                            ctitulocurso = { loscursos.titulo }
                            cimgcertificado = { loscursos.imgcertificado }
                            corientacion = {loscursos.orientacion}
                            cnombresapellidos = { consola.f_nombresapellidos.toUpperCase()}
                            cci = { consola.f_ci.toUpperCase() } 
                            cnombrestop = {loscursos.nombrestop}
                            cnombresleft = {loscursos.nombresleft}
                            cqrtop = {loscursos.qrtop}
                            cqrleft = {loscursos.qrleft}
                            ccitop = {loscursos.citop}
                            ccileft = {loscursos.cileft} />
                    }
                    
                    {consola.certif==='N' &&
                      <Alert severity="warning">Usted tiene su certificado pero no encontramos su depósito. Si desea obtener su certificado y descargarlo escribir
                      <Link 
                          display="block" 
                          variant="body1" 
                          href= {"https://api.whatsapp.com/send?phone=59170117490 &text= Quiero obtener el diplomado *" + loscursos.titulo + "*"}
                          key={consola.id}
                          rel="noreferrer" // rel="noopener"
                          target="_blank"> <Send /> Click aqui</Link>
                      </Alert>
                    }
                </div>
            ))}
              <div align="right"> <br />
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Search />}
                    disableElevation
                    className={classes.button}
                    disabled={!cicelular || cicelular?.cicelular || loading}
                    onClick={()=>peticionBusca(loscursos.cdescarga)} >
                      Buscar
                </Button> {loading && <CircularProgress size={24} className={classes.buttonProgress} />}&nbsp;&nbsp;
                <Button
                    variant="outlined"
                    color="default"
                    size="small"
                    className={classes.button}
                    startIcon={<Cancel />}
                    onClick={()=>abrirCerrarModalBusca()} >
                    Cerrar
                </Button>
           </div>
        </div>
    )

    const bodySesiones = (
      <div className={classes.modal}>
          <h3>{loscursos && loscursos.titulo}</h3>
              <FormHelperText>Ingrese celular o C.I. para ver las sesiones o descargar el material digital.</FormHelperText>
                <TextField
                  fullWidth
                  label="Celular o C.I."
                  name="cicelular"
                  className={classes.inputMaterial}
                  placeholder="Ingrese Celular o CI"
                  inputProps = {{maxLength: 100}}
                  margin="dense"
                  variant="outlined"
                  required
                  onBlur = {handleChange}
                  onChange = {handleChange}
                  error = {Boolean(cicelular?.cicelular)}
                  helperText = {(cicelular?.cicelular)}
            />
              { mostrar.map((consola) => (
                  <div align="left" key={consola.id}>
                      { consola.id && <FormHelperText> <TableRow>
                            <TableRow>Nombres: {consola.f_nombresapellidos.toUpperCase()} </TableRow>
                            <TableRow>C.I.: {consola.f_ci.toUpperCase()} </TableRow>
                            <TableRow>Celular: {consola.f_celular} </TableRow>
                            <TableRow>Departamento: {consola.f_departamento} </TableRow>
                        </TableRow> </FormHelperText>
                      }
                      <Divider />
                      {consola.msg && <Alert severity="warning">{consola.msg}</Alert>}
                      {consola[loscursos.cdescarga] =='S' &&
                        <div>
                            <Accordion expanded={expandedacord === 'panel1'} onChange={handleChangeacord('panel1')}>
                              <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1bh-content"
                                  id="panel1bh-header"
                                ><Typography className={classes.heading}>Sesiones grabadas</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                  <Typography>
                                    {loscursos.sesionesgrabadas && loscursos.sesionesgrabadas.map((ses) => (
                                        <Link 
                                          display="block" 
                                          variant="body1" 
                                          href={ses.sesionlink} 
                                          key={ses}
                                          rel="noreferrer" // rel="noopener"
                                          target="_blank">
                                          <Grid container direction="column" spacing={1} alignItems="flex-start">
                                            <Grid item><LiveTv /> {ses.sesiondesc}</Grid>
                                          </Grid>
                                        </Link>
                                    ))}
                                  </Typography>
                              </AccordionDetails>
                            </Accordion>
                            <Accordion expanded={expandedacord === 'panel2'} onChange={handleChangeacord('panel2')}>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                              >
                                <Typography className={classes.heading}>Material digital </Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Typography>
                                  <AttachFile  /> Link roto
                                </Typography>
                              </AccordionDetails>
                            </Accordion>
                        </div>
                      }
                      {consola.certif==='N' &&
                        <Alert severity="warning">Para ver las sesiones grabadas o descargar el material digital del Diplomado, debe cancelar su cuota incial.
                        <Link 
                            display="block" 
                            variant="body1" 
                            href= {"https://api.whatsapp.com/send?phone=59170117490 &text= Quiero obtener el diploma del *" + loscursos.titulo + "* y acceso a las sesiones grabadas o material digital."}
                            key={consola.id}
                            rel="noreferrer" // rel="noopener"
                            target="_blank"> <Send /> Click aqui</Link>
                        </Alert>
                      }
                  </div>
              ))}

              <div align="right"> <br />
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<Search />}
                    disableElevation
                    className={classes.button}
                    disabled={!cicelular || cicelular?.cicelular || loading}
                    onClick={()=>peticionBusca(loscursos.cdescarga)} >
                      Buscar
                </Button> {loading && <CircularProgress size={24} className={classes.buttonProgress} />}&nbsp;&nbsp;
                <Button
                    variant="outlined"
                    color="default"
                    size="small"
                    className={classes.button}
                    startIcon={<Cancel />}
                    onClick={()=>abrirCerrarModalSesiones()} >
                    Cerrar
                </Button>
              </div>
      </div>
   )

  return (
    <Grid className={classes.root} container item xs={12} md={8} spacing={1}>
      <Typography variant="h6">
          {title}
      </Typography>
      <Divider />
      {posts.map((post) => (
      <Grid item xs={12} sm={6} key={post.img}>
            <Card className={classes.root} key={post.id}>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className={classes.activo}>
                    <post.activo />
                  </Avatar>
                }
                action={
                  <IconButton aria-label="settings">
                    <MoreVertIcon />
                  </IconButton>
                }
                title={post.titulo}
                subheader={post.fechapublicacion}
              />
              <CardMedia
                className={classes.media}
                image={post.img}
                title="www.cybermaq.com"
              />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {post.descripcion}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton 
                rel="noreferrer" // rel="noopener"
                target="_blank"
                href = "https://www.facebook.com/ClassOnline-110882097751924"
                aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton 
                rel="noreferrer" // rel="noopener"
                target="_blank"
                href = "https://api.whatsapp.com/send?text=Te%20recomiendo%20esta%20plataforma%20de%20cursos%20virtuales%20me%20ha%20ayudado%20mucho%20https://classonline.cybermaq.com"
                aria-label="share">
                <ShareIcon />
              </IconButton>
              <IconButton
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>

                <BottomNavigation
                    key={post.id}
                    value={5} // value
                    onChange={(event, newValue) => {
                        setValue(newValue);
                    }}
                    showLabels
                    className={classes.root}
                    >
                    <BottomNavigationAction onClick={()=>abrirCerrarModalInsertar()} disabled={!post.registrarse} label="Inscribirse" icon={<PersonAddIcon />} />
                    {/* <BottomNavigationAction onClick={()=>abrirCerrarModalBusca(post)} disabled={!post.certificado}  label="Certificado" icon={<PhotoAlbumIcon />} /> */}
                    <BottomNavigationAction onClick={()=>abrirCerrarModalSesiones(post)} disabled={!post.sesiones} label="Sesiones grabadas" icon={<VideocamIcon />} />
                </BottomNavigation>

            </Collapse>
          </Card>
        </Grid>
      ))}
          <Modal
          open = {modalInsertar}
          onClose = {abrirCerrarModalInsertar}>
            {bodyInsertar}
          </Modal>

          <Modal
          open = {modalBusca}
          onClose = {abrirCerrarModalBusca}>
            {bodyBusca}
          </Modal>

          <Modal
          open = {modalSesiones}
          onClose = {abrirCerrarModalSesiones}>
            {bodySesiones}
          </Modal>

          <Notificaciones
               notify={notify}
               setNotify={setNotify}
          />
    </Grid>
  );
}

MainDiplomados.propTypes = {
  posts: PropTypes.array,
  sesionesgrabadas: PropTypes.array,
  title: PropTypes.string,
};
